import { element } from 'protractor';
import { DataService } from './../../../services/data.service';
import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-suggested',
  templateUrl: './suggested.component.html',
  styleUrls: ['./suggested.component.css', '../digi-library-assets/css/style.css']
})
export class SuggestedComponent implements OnInit {

  vendors = []

  constructor(private data: DataService) { }

  ngOnInit(): void {
    $('.footer').hide();
		var viewportWidth = $(window).width();
		if (viewportWidth <= 991) {
			$('.mob-logo').hide();
			$('.breadcrumb-com').hide()
			$(' .breadcrumb-mob').show()
		}else{
			$('.breadcrumb-mob').hide()
			$('.breadcrumb-com').show()
		}
		
    this.data.getVendors().then((data:any[]) =>{
			this.vendors = data.filter(
				(p:any) => p.feed_type !== "OPDS"
			);
    });
  }

  openVendorUrl(url){
    window.open(url,"_blank")
  }
}
