<div class="p-4 p-md-5 pt-5">
  <!---Recently Added-->
  <div style='margin-bottom:40px;'>
    <div class="row">
      <div class="col-md-6" style='text-align:left'>
        <p style='font-weight:600; font-size:18px; color:#033600;'>Suggested Resources:</p>
      </div>
    </div>

    <div class="row">
      <div *ngFor="let v of vendors" >
        <div 
					(click)="openVendorUrl(v.feed_url)" 
					[ngStyle]="{'background-image':'url('+v.logo_url+')'}"  
					class='item-display-cards' 
					style="">
        </div>
      </div>
    </div>
  </div>
</div>